import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

import './MainLayout.scss';

const MainLayout = ({ children, backgroundImage }) => {
  return (
    <div className="MainLayout">
      <Navbar />
      <header style={{ backgroundImage: `url(${backgroundImage})` }} className="MainLayout__background">
        <div className="MainLayout__main-div">{children}</div>
      </header>
      <Footer />
    </div>
  );
};

export default MainLayout;
