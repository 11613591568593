import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Signup from '../../pages/Signup';
import AccountCreated from '../../pages/AccountCreated';
import AccountExists from '../../pages/AccountExists';

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${location.pathname}${location.search}` });

  }, [location]);

  return (
    <Switch>
      <Route exact path="/" component={Signup} />
      <Route path="/accountCreated" component={AccountCreated} />
      <Route path="/accountExists" component={AccountExists} />
      <Route path="*">
        <Redirect to={Signup} />
      </Route>
    </Switch>
  );
};

export default Routes;
