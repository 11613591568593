import React from 'react';
import { Helmet } from 'react-helmet';
import MainLayout from '../../layout/MainLayout/MainLayout';
import SignupForm from '../../components/SignupForm';
import { RegistrationIdStatusCodes, SignupResultCodes } from '../../utils/SignupConstants';
import { SIGNUP_URL } from '../../utils/ServiceConstants';
import { redirectToLogin } from '../../utils/NavigationUtils';
import headerImage from '../../images/zion-switchbacks.jpg';
import image1 from '../../images/driveamerica-1.jpg';
import image2 from '../../images/driveamerica-2.jpg';
import image3 from '../../images/driveamerica-3.jpg';

import './Signup.scss';

class Signup extends React.Component {
  state = {};

  componentDidMount() {
    this.validateRegistrationId();
  }

  /**
   * Validates the registation ID.
   */
  validateRegistrationId() {
    let params = new URLSearchParams(window.location.search);
    let id = params.get('id');
    if (id) {
      let url = SIGNUP_URL + '?action=validateid&registrationId=' + id;
      this.sendRequestJsonResponse(url)
        .then((data) => {
          this.setState({
            registrationId: id,
            registrationStatus: data.status,
            responseBody: data.body,
          });
        })
        .catch((error) => {});
    }
  }

  /**
   * Sends the request the URL.
   * @param {String} url
   */
  sendRequestJsonResponse(url) {
    //Send and get the response status code.
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
    }).then((response) => response.json().then((data) => ({ status: response.status, body: data })));
  }

  /**
   * Sends the request the URL.
   * @param {String} url
   */
  sendRequest(url) {
    //Send and get the response status code.
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
    }).then((response) => response.status);
  }

  /**
   * Gets the header content.
   */
  getHeader() {
    return (
      <div className="Signup__header">
        <div className="Signup__header-image" style={{ backgroundImage: `url(${headerImage})` }}>
          <div className="Signup__header-image-text">
            <span>Sign Up</span>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Gets the text content.
   */
  getText() {
    return (
      <div className="Signup__intro">
        <div className="Signup__intro-text">
          <span>Why sign up with ATI?</span>
          <ul>
            <li>
              Access our industry leading booking platform where you can easily design your customer's next American
              road trip!
            </li>
            <li>Unbeatable rates - all products and services are directly contracted</li>
            <li>Discover ATI's curated Drive America packages - get ready to hit the road!</li>
            <li>Book over 30,000 accommodation, attractions, transportation providers across North America</li>
            <li>Access to scarce inventory in and around America's National Parks</li>
            <li>Flexible booking terms and 24/7 U.S. based customer support </li>
          </ul>
        </div>
        <div className="Signup__intro-image">
          <img src={image1} className="Signup__intro-image-1" alt="lodging" />
          <img src={image2} className="Signup__intro-image-2" alt="lodging" />
          <img src={image3} className="Signup__intro-image-3" alt="lodging" />
        </div>
      </div>
    );
  }

  /**
   * Gets the form to display.
   */
  getForm() {
    if (this.state.registrationStatus) {
      switch (this.state.registrationStatus) {
        case RegistrationIdStatusCodes.OK:
        case RegistrationIdStatusCodes.ADDITIONAL_USER_OK:
          return (
            <SignupForm
              registrationId={this.state.registrationId}
              registrationStatus={this.state.registrationStatus}
              responseBody={this.state.responseBody}
              history={this.props.history}
            />
          );
        case RegistrationIdStatusCodes.UNDER_REVIEW:
          //redirect
          this.props.history.push({
            pathname: '/accountExists',
            state: {
              signupResultCode: SignupResultCodes.OK_UNDER_REVIEW,
            },
          });
          return <div />;
        case RegistrationIdStatusCodes.ADDITIONAL_USER_ERROR:
          //redirect
          this.props.history.push({
            pathname: '/accountExists',
            state: {
              signupResultCode: SignupResultCodes.OK,
            },
          });
          return <div />;
        case RegistrationIdStatusCodes.ERROR:
        default:
          redirectToLogin();
          return <div />;
      }
    } else {
      return <SignupForm history={this.props.history} />;
    }
  }

  /**
   * Creates the message content.
   * @param {string} message
   */
  createMessage(message) {
    return (
      <div className="Signup__message">
        <span className="Signup__message-text">{message}</span>
      </div>
    );
  }

  /**
   * Gets components to display.
   */
  getChildren() {
    return (
      <div className="Signup">
        <Helmet title="Sign Up" >
          <meta name="facebook-domain-verification" content="bbibs5j66u5o577fu0o37dnzvklamn" />
        </Helmet>
        {this.getHeader()}
        {this.getText()}
        {this.getForm()}
      </div>
    );
  }

  render() {
    return <MainLayout children={this.getChildren()} />;
  }
}

export default Signup;
