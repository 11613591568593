export const SignupResultCodes = {
  OK: 200,
  OK_UNDER_REVIEW: 202,
  EMAIL_EXISTS: 406,
  USER_EXISTS: 409,
  INVALID_CAPTCHA: 412,
  INVALID_FORM_DATA: 417,
};

export const RegistrationIdStatusCodes = {
  OK: 200,
  ADDITIONAL_USER_OK: 201,
  UNDER_REVIEW: 202,
  ADDITIONAL_USER_ERROR: 409,
  ERROR: 400,
};

export const SignupMessages = {
  ACCOUNT_CREATED_LOGIN:
    'The account has been created and you can begin earning commissions on your bookings. Please contact support if you have any questions.',
  ACCOUNT_CREATED_UNDER_REVIEW:
    'The account has been created and is under review. We will reach out to you with further information. Please contact support if you have any questions.',
};

export const SignupFormMessages = {
  ERROR_USERNAME_EXISTS: 'The username already exists.',
  ERROR_INVALID_CAPTCHA: 'Invalid captcha.',
  ERROR_PASSWORDS_DO_NOT_MATCH: 'The passwords do not match.',
  ERROR_USERNAME_IS_INVALID:
    'The username is not valid. Username must be alphanumeric and between 6-13 characters long and must include a letter. No special characters are permitted',
  ERROR_SELECT_COUNTRY: 'Please select a country.',
  ERROR_GENERAL: 'An error occurred during the registration process. Please contact support.',
};
