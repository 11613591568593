import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { createLoginLink, redirectToLogin } from '../../utils/NavigationUtils';
import { SignupResultCodes, SignupMessages } from '../../utils/SignupConstants';
import MainLayout from '../../layout/MainLayout/MainLayout';
import headerImage from '../../images/account.jpg';
import './AccountExists.scss';

const AccountExists = (props) => {
  /**
   * Gets the header content.
   */
  const getHeader = () => {
    return (
      <div className="AccountExists__header">
        <div className="AccountExists__header-image" style={{ backgroundImage: `url(${headerImage})` }}>
          <div className="AccountExists__header-image-text">
            <span>Thank you for registering</span>
          </div>
        </div>
      </div>
    );
  };

  /**
   * Gets the message content.
   */
  const getMessage = () => {
    if (props.location && props.location.state) {
      let signupResultCode = props.location.state.signupResultCode;

      switch (signupResultCode) {
        case SignupResultCodes.OK:
          return createMessage(SignupMessages.ACCOUNT_CREATED_LOGIN, true);
        case SignupResultCodes.OK_UNDER_REVIEW:
        default:
          return createMessage(SignupMessages.ACCOUNT_CREATED_UNDER_REVIEW, false);
      }
    }

    //redirect
    redirectToLogin();

    return <div />;
  };

  /**
   * Creates the message content.
   * @param {string} message
   */
  const createMessage = (message, logIn) => {
    return (
      <div className="AccountExists">
        <div className="AccountExists__message">
          <span className="AccountExists__message-text">{message}</span>
          {logIn && <span className="AccountExists__message-login">Click {createLoginLink('here')} to log in.</span>}
        </div>
      </div>
    );
  };

  /**
   * Gets the components to display.
   */
  const getChildren = () => {
    return (
      <Fragment>
        <Helmet title="Thank you for registering" />
        {getHeader()}
        {getMessage()}
      </Fragment>
    );
  };

  return <MainLayout children={getChildren()} />;
};

export default AccountExists;
