import React from 'react';

const TEST_DOMAIN = 'test.americantours.com';

const TEST_URL = 'https://test.americantours.com';
const PROD_URL = 'https://www.americantours.com';

const FORGOT_PASSWORD_PATH = '/ati/forgotPassword';
const FORGOT_USERNAME_PATH = '/ati/forgotUsername';

export const createLoginLink = (children) => {
  if (window.location.href.indexOf(TEST_DOMAIN) !== -1) {
    return <a href={TEST_URL}>{children}</a>;
  }
  return <a href={PROD_URL}>{children}</a>;
};

export const createPasswordLink = (children) => {
  if (window.location.href.indexOf(TEST_DOMAIN) !== -1) {
    return <a href={TEST_URL + FORGOT_PASSWORD_PATH}>{children}</a>;
  }
  return <a href={PROD_URL + FORGOT_PASSWORD_PATH}>{children}</a>;
};

export const createUsernameLink = (children) => {
  if (window.location.href.indexOf(TEST_DOMAIN) !== -1) {
    return <a href={TEST_URL + FORGOT_USERNAME_PATH}>{children}</a>;
  }
  return <a href={PROD_URL + FORGOT_USERNAME_PATH}>{children}</a>;
};

export const redirectToLogin = () => {
  if (window.location.href.indexOf(TEST_DOMAIN) !== -1) {
    window.location.replace(TEST_URL);
  } else {
    window.location.replace(PROD_URL);
  }
};
