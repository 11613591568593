import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { RegistrationIdStatusCodes, SignupResultCodes, SignupFormMessages } from '../../utils/SignupConstants';
import { CAPTCHA_SERVLET_URL, SIGNUP_URL } from '../../utils/ServiceConstants';
import { createPasswordLink, createUsernameLink } from '../../utils/NavigationUtils';
import './SignupForm.scss';

const PRIVACY_POLICY_PATH = '/content/register/privacypolicy';

const PRIVACY_POLICY_DEFAUL_PATH = 'privacypolicy';

class SignupForm extends React.Component {
  captchaId = '';
  selectRef = null;

  state = {
    captchaSource: '',
    errorTextStep1: '',
    errorTextStep2: '',
    active: false,
    activeFormId: 1,
    countries: {},
    privacyPolicy: '',
  };

  componentDidMount() {
    this.selectRef = React.createRef();

    this.captchaId = uuidv4();
    this.setState({
      captchaSource: CAPTCHA_SERVLET_URL + this.captchaId,
      countries: countryList().getData(),
      country: { value: 'US', label: 'United States' },
    });
    this.loadPrivacyPolicy();
  }

  isUserAdd() {
    return this.props.registrationStatus === RegistrationIdStatusCodes.ADDITIONAL_USER_OK;
  }

  /**
   * Loads the privacy policy.
   */
  loadPrivacyPolicy() {
    //Get the privacy policy text.
    fetch(PRIVACY_POLICY_PATH)
      //Get the resonse.
      .then((x) => {
        //Success
        if (x && x.status === 200) {
          //Set the text from the response
          x.text().then((text) => {
            this.setState({
              privacyPolicy: text,
            });
          });
        }
        //Fail
        else {
          //Get the privacy policy from the default file.
          fetch(PRIVACY_POLICY_DEFAUL_PATH)
            .then((x) => {
              return x.text();
            })
            .then((text) => {
              this.setState({
                privacyPolicy: text,
              });
            });
        }
      });
  }

  /**
   * Creates a general error message.
   */
  createGeneralErrorMessage() {
    return (
      <p style={{ margin: 0 }}>
        An error occurred during the registration process. Please contact{' '}
        <a href="mailto:sales@americantours.com">sales@americantours.com</a>.
      </p>
    );
  }

  /**
   * Creates a email error message.
   */
  createEmailErrorMessage() {
    return (
      <p style={{ margin: 0 }}>
        This email has already been registered. Forgot your {createUsernameLink('username')} or{' '}
        {createPasswordLink('password')}?
      </p>
    );
  }

  /**
   * Handles the update event.
   * @param {Event} event
   */
  handleUpdate = (event) => {
    //Set the changed value.
    if (event.target.name) {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  /**
   * Validate that the text is alphanumeric.
   * @param {Event} e
   */
  validateAlphanumeric(e) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  render() {
    return (
      <div>
        <div className="SignupForm__step-header">Step 1: Share Your Information</div>
        <div className={this.state.activeFormId === 1 ? 'SignupForm__step-active' : 'SignupForm__step-inactive'}>
          {this.createForm1()}
        </div>
        <div className="SignupForm__step-header">Step 2: Share Your Travel Agency Information</div>
        <div className={this.state.activeFormId === 2 ? 'SignupForm__step-active' : 'SignupForm__step-inactive'}>
          {this.createForm2()}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Step 1 functions
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Handles the submit event.
   * @param {Event} event
   */
  handleSubmitStep1 = (event) => {
    event.preventDefault();

    //Disable the form elements.
    if (document.getElementById('nextBtn')) {
      document.getElementById('nextBtn').disabled = true;
    }

    this.setState({
      active: true,
    });

    let error = this.validateFormStep1();
    if (!error) {
      this.submitFormStep1(event);
    } else {
      //Reset the form values.
      this.resetFormStep1();

      //Set the error message.
      this.setState({ errorTextStep1: error });
    }
  };

  /**
   * Reset the form elements.
   */
  resetFormStep1() {
    this.setState({
      active: false,
    });

    if (document.getElementById('nextBtn')) {
      document.getElementById('nextBtn').disabled = false;
    }
  }

  /**
   * Validate the form values.
   */
  validateFormStep1 = () => {
    let error;

    //Check that the username is valid format.
    const re = /^[a-zA-Z0-9]{6,13}$/g;
    const re2 = /^[0-9]{6,13}$/g;
    if (
      this.state.username.length > 6 && // At least 6 chars
      this.state.username.length < 13 && // No more than 13 chars
      re.test(this.state.username) && // must be alphanumeric
      !re2.test(this.state.username) // can not be only numeric
    ) {
    } else {
      error = SignupFormMessages.ERROR_USERNAME_IS_INVALID;
      document.getElementById('username').select();
    }

    //Check that the passwords match.
    if (this.state.password !== this.state.confirmPassword) {
      error = SignupFormMessages.ERROR_PASSWORDS_DO_NOT_MATCH;
      document.getElementById('confirmPassword').select();
    }

    return error;
  };

  /**
   * Submit the form.
   */
  submitFormStep1 = (event) => {
    let error = this.createGeneralErrorMessage();

    //Send the data.
    this.sendDataStep1()
      .then((status) => {
        //Check the response status.
        if (status) {
          switch (status) {
            case SignupResultCodes.OK:
              error = null;
              this.setState({
                activeFormId: 2,
              });
              break;
            case SignupResultCodes.USER_EXISTS:
              //The username exists.
              error = SignupFormMessages.ERROR_USERNAME_EXISTS;
              document.getElementById('username').select();
              break;
            case SignupResultCodes.EMAIL_EXISTS:
              error = this.createEmailErrorMessage();
              document.getElementById('email').select();
              break;
            case SignupResultCodes.INVALID_FORM_DATA:
              //Invalid form data - should not occur unless form validation is bypassed.
              error = SignupFormMessages.ERROR_USERNAME_IS_INVALID;
              document.getElementById('username').select();
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {})
      .finally(() => {
        //Reset the form values.
        this.resetFormStep1();

        //Set the error message.
        this.setState({
          errorTextStep1: error,
        });
      });
  };

  /**
   * Sends the form data to the URL.
   */
  sendDataStep1 = () => {
    //Create the form data.
    let formData = new FormData();
    formData.append('action', 'validateuser');
    if (this.state.username) {
      formData.append('username', this.state.username);
    }
    if (this.state.email) {
      formData.append('email', this.state.email);
    }

    //Send and get the response status code.
    return fetch(SIGNUP_URL, {
      method: 'POST',
      mode: 'cors',
      body: formData,
    }).then((response) => response.status);
  };

  clearForm() {
    document.getElementById('signup-form').reset();
  }

  /**
   * Creates the form components.
   */
  createForm1() {
    return (
      <div className="SignupForm">
        <LoadingOverlay active={this.state.active} spinner={true} text="Sending...">
          <div className="SignupForm__form-div">
            <div className="SignupForm__center-div">
              <form
                id="signup-form"
                className="SignupForm__form"
                onSubmit={(event) => {
                  this.handleSubmitStep1(event);
                }}
                onChange={(event) => {
                  this.handleUpdate(event);
                }}
              >
                <div className="SignupForm__fields-div">
                  <fieldset id="signupFormFieldset" className="SignupForm__fieldset">
                    <input
                      id="firstname"
                      name="firstname"
                      type="text"
                      placeholder="*First Name"
                      required
                      className="SignupForm__field"
                      maxLength="80"
                    />
                    <input
                      id="lastname"
                      name="lastname"
                      type="text"
                      placeholder="*Last Name"
                      required
                      className="SignupForm__field"
                      maxLength="80"
                    />
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="*Email Address"
                      required
                      className="SignupForm__field"
                      maxLength="80"
                    />
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      placeholder="*Phone Number"
                      required
                      className="SignupForm__field"
                      maxLength="20"
                    />
                    <input
                      id="username"
                      name="username"
                      type="text"
                      placeholder="*Username"
                      required
                      className="SignupForm__field"
                      onKeyPress={(e) => this.validateAlphanumeric(e)}
                      maxLength="13"
                    />
                    <input
                      id="password"
                      name="password"
                      type="password"
                      placeholder="*Password"
                      required
                      className="SignupForm__field"
                      maxLength="30"
                    />
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      placeholder="*Confirm password"
                      required
                      className="SignupForm__field"
                      maxLength="30"
                    />
                  </fieldset>
                </div>
                {this.state.errorTextStep1 && (
                  <span className="SignupForm__error-text">{this.state.errorTextStep1}</span>
                )}
                {this.state.errorTextStep2 && (
                  <span className="SignupForm__error-text">{this.state.errorTextStep2}</span>
                )}
                <div className="SignupForm__submit-div">
                  <button
                    id="cancelBtn"
                    type="button"
                    className="SignupForm__cancel-btn"
                    onClick={() => this.clearForm()}
                  >
                    Cancel
                  </button>
                  <button id="nextBtn" type="submit" className="SignupForm__submit-btn">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Step 2 functions
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Handles the submit event.
   * @param {Event} event
   */
  handleSubmitStep2 = (event) => {
    event.preventDefault();

    this.setState({
      active: true,
    });

    let error = this.validateFormStep2();
    if (!error) {
      this.submitFormStep2();
    } else {
      //Reset the form values.
      this.resetFormStep2();

      //Set the error message.
      this.setState({ errorTextStep2: error });
    }
  };

  /**
   * Reset the form elements.
   */
  resetFormStep2() {
    this.captchaId = uuidv4();

    this.setState({
      active: false,
      captchaSource: CAPTCHA_SERVLET_URL + this.captchaId,
    });
  }

  /**
   * Validate the form values.
   */
  validateFormStep2 = () => {
    let error;

    //Check the country.
    if (!this.state.country) {
      error = SignupFormMessages.ERROR_SELECT_COUNTRY;
      this.selectRef.current.focus();
    }

    return error;
  };

  /**
   * Submit the form.
   */
  submitFormStep2 = () => {
    let error = this.createGeneralErrorMessage();

    //Send the data.
    this.sendDataStep2(SIGNUP_URL)
      .then((status) => {
        //Check the response status.
        if (status) {
          switch (status) {
            case SignupResultCodes.OK:
            case SignupResultCodes.OK_UNDER_REVIEW:
              error = null;
              //redirect
              this.props.history.push({
                pathname: '/accountCreated',
                state: {
                  signupResultCode: status,
                },
              });
              break;
            case SignupResultCodes.USER_EXISTS:
              //The username exists.
              error = SignupFormMessages.ERROR_USERNAME_EXISTS;
              document.getElementById('username').select();
              break;
            case SignupResultCodes.INVALID_CAPTCHA:
              //Invalid captcha.
              error = SignupFormMessages.ERROR_INVALID_CAPTCHA;
              document.getElementById('captcha').select();
              break;
            case SignupResultCodes.INVALID_FORM_DATA:
              //Invalid form data - should not occur.
              console.log('Invalid form data was sent in the request.');
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {})
      .finally(() => {
        //Reset the form values.
        this.resetFormStep2();

        //Set the error message.
        this.setState({
          errorTextStep2: error,
        });
      });
  };

  /**
   * Sends the form data to the URL.
   * @param {string} url
   */
  sendDataStep2 = (url) => {
    //Create the form data.
    let formData = new FormData();
    formData.append('action', 'register');
    if (this.props.registrationId) {
      formData.append('registrationId', this.props.registrationId);
    }
    if (this.state.firstname) {
      formData.append('firstname', this.state.firstname);
    }
    if (this.state.lastname) {
      formData.append('lastname', this.state.lastname);
    }
    if (this.state.email) {
      formData.append('email', this.state.email);
    }
    if (this.state.phone) {
      formData.append('phone', this.state.phone);
    }
    if (this.state.username) {
      formData.append('username', this.state.username);
    }
    if (this.state.password) {
      formData.append('password', this.state.password);
    }
    if (this.state.companyName) {
      formData.append('companyName', this.state.companyName);
    }
    if (this.state.companyAddress) {
      formData.append('companyAddress', this.state.companyAddress);
    }
    if (this.state.city) {
      formData.append('city', this.state.city);
    }
    if (this.state.state) {
      formData.append('state', this.state.state);
    }
    if (this.state.zipcode) {
      formData.append('zipcode', this.state.zipcode);
    }
    if (this.state.country) {
      formData.append('country', this.state.country.label);
    }
    if (this.state.companyPhone) {
      formData.append('companyPhone', this.state.companyPhone);
    }
    if (this.state.internetAddress) {
      formData.append('internetAddress', this.state.internetAddress);
    }
    if (this.state.code) {
      formData.append('code', this.state.code);
    }
    if (this.state.captcha) {
      formData.append('captcha', this.state.captcha);
    }
    if (this.captchaId) {
      formData.append('captchaId', this.captchaId);
    }

    //Send and get the response status code.
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: formData,
    }).then((response) => response.status);
  };

  getHostAgencyFields() {
    return (
      <div>
        <input
          id="hostCompanyName"
          name="hostCompanyName"
          type="text"
          className="SignupForm__field"
          disabled
          maxLength="80"
          value={this.props.responseBody.company_name}
        />
        <input
          id="hostCompanyAddress"
          name="hostCompanyAddress"
          type="text"
          value={this.props.responseBody.company_address}
          className="SignupForm__field"
          disabled
          maxLength="80"
        />
        <input
          id="hostCity"
          name="hostCity"
          type="text"
          value={this.props.responseBody.town_city}
          className="SignupForm__field"
          disabled
          maxLength="80"
        />
        <input
          id="hostState"
          name="hostState"
          type="text"
          value={this.props.responseBody.state}
          className="SignupForm__field"
          maxLength="80"
          disabled
        />
        <input
          id="hostZipcode"
          name="hostZipcode"
          type="text"
          value={this.props.responseBody.zip_code}
          className="SignupForm__field"
          maxLength="20"
          disabled
        />
        <input
          id="hostCountry"
          name="hostCountry"
          value={this.props.responseBody.country}
          className="SignupForm__field"
          type="text"
          disabled
        />
        <input
          id="hostCompanyPhone"
          name="hostCompanyPhone"
          type="text"
          value={this.props.responseBody.phone}
          className="SignupForm__field"
          maxLength="20"
          disabled
        />
        <input
          id="hostInternetAddress"
          name="hostInternetAddress"
          type="text"
          value={this.props.responseBody.inet}
          className="SignupForm__field"
          disabled
        />
        <input
          id="hostCode"
          name="hostCode"
          type="text"
          value={this.props.responseBody.code}
          className="SignupForm__field"
          disabled
        />
      </div>
    );
  }

  getForm2NewUserFields() {
    return (
      <div>
        <input
          id="companyName"
          name="companyName"
          type="text"
          placeholder="*Company Name "
          className="SignupForm__field"
          required
          maxLength="80"
        />
        <input
          id="companyAddress"
          name="companyAddress"
          type="text"
          placeholder="*Address"
          className="SignupForm__field"
          required
          maxLength="80"
        />
        <input
          id="city"
          name="city"
          type="text"
          placeholder="*Town/City"
          className="SignupForm__field"
          required
          maxLength="80"
        />
        <input
          id="state"
          name="state"
          type="text"
          placeholder="*State"
          className="SignupForm__field"
          maxLength="80"
          required
        />
        <input
          id="zipcode"
          name="zipcode"
          type="text"
          placeholder="*ZIP Code"
          className="SignupForm__field"
          maxLength="20"
          required
        />
        <Select
          placeholder="*Country"
          options={this.state.countries}
          onChange={(country) => this.setState({ country: country })}
          className="SignupForm__select"
          classNamePrefix="select-element"
          required
          ref={this.selectRef}
          value={this.state.country}
        />
        <input
          id="companyPhone"
          name="companyPhone"
          type="text"
          placeholder="*Company Phone Number"
          className="SignupForm__field"
          required
          maxLength="20"
        />
        <input
          id="internetAddress"
          name="internetAddress"
          type="text"
          placeholder="Internet Address/Website"
          className="SignupForm__field"
        />
        {!this.isUserAdd() && (
          <input
            id="code"
            name="code"
            type="text"
            placeholder="IATA/ARC/CLIA/TrueID Code"
            className="SignupForm__field"
          />
        )}
      </div>
    );
  }
  /**
   * Creates the form components.
   */
  createForm2() {
    return (
      <div className="SignupForm">
        <LoadingOverlay active={this.state.active} spinner={true} text="Sending...">
          <div className="SignupForm__form-div">
            <div className="SignupForm__center-div">
              <form
                id="signup-form2"
                className="SignupForm__form"
                onSubmit={(event) => {
                  this.handleSubmitStep2(event);
                }}
                onChange={(event) => {
                  this.handleUpdate(event);
                }}
              >
                <div className="SignupForm__fields-div">
                  <fieldset id="signupFormFieldset" className="SignupForm__fieldset">
                    <h3>Your Agency Information</h3>
                    {!this.isUserAdd() && <p>We will use the information below for commission payments.</p>}
                    {this.getForm2NewUserFields()}
                    {this.isUserAdd() && (
                      <div>
                        <h3>Host Agency Information</h3>
                        <p>We will use the information below for commission payments.</p>
                        {this.getHostAgencyFields()}
                      </div>
                    )}
                    <input
                      id="captcha"
                      name="captcha"
                      type="text"
                      required
                      placeholder="*Type the characters below"
                      className="SignupForm__field"
                      maxLength="20"
                    />
                    <div className="SignupForm__captcha-div">
                      <img src={this.state.captchaSource} className="SignupForm__captcha-image" alt="" />
                    </div>
                    <span className="SignupForm__privacypolicy-span">Privacy Policy</span>
                    <textarea
                      id="privacyPolicy"
                      name="privacyPolicy"
                      value={this.state.privacyPolicy}
                      className="SignupForm__field SignupForm__privacypolicy-textarea"
                      readOnly="true"
                    />
                    <div className="SignupForm__privacypolicy-accept">
                      <input
                        id="privacyPolicyAccept"
                        name="privacyPolicyAccept"
                        type="checkbox"
                        required
                        className="SignupForm__privacypolicy-accept-checkbox"
                      />
                      <span className="SignupForm__privacypolicy-accept-span">I accept the Privacy Policy</span>
                    </div>
                  </fieldset>
                </div>
                {this.state.errorTextStep2 && (
                  <span className="SignupForm__error-text">{this.state.errorTextStep2}</span>
                )}
                <div className="SignupForm__submit-div">
                  <button
                    id="previousBtn"
                    type="button"
                    className="SignupForm__cancel-btn"
                    onClick={() => this.setState({ activeFormId: 1 })}
                  >
                    Back
                  </button>
                  <button id="submitBtn" type="submit" className="SignupForm__submit-btn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default SignupForm;
